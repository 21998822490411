<template>
  <b-card>
    <form-header
      :title="headerTitle"
      :is-next-disabled="isNextDisabled"
      @cancel-action="$emit('cancel-action')"
      @next-action="$emit('next-action')"
    />
    <input
      ref="csvFileInput"
      type="file"
      accept="text/csv"
      hidden
      @change="handleFileUpload"
    />
    <div v-if="isFileLoaded">
      <b-row class="mt-5 mb-4">
        <b-col sm="12" md="6" lg="4" xl="3">
          <div class="search-filter d-flex flex-row align-items-center px-3">
            <font-awesome-icon class="icon" icon="search" />
            <b-form-input
              v-model="filterByName"
              class="flex-fill"
              placeholder="Buscar"
              size="sm"
              type="search"
            />
          </div>
        </b-col>
      </b-row>
      <z-table
        :items="parsedBillingInfo"
        :fields="fields"
        :busy="isLoading"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <loading-spinner />
          </div>
        </template>
        <template v-slot:cell(subtotal)="row">
          <b-form-input
            v-model="row.item.subtotal"
            v-validate="'min_value:0.01|decimal:2'"
            type="number"
            name="subtotal"
            min="0"
            trim
            @input="row.item.subtotalChanged(row.item)"
          ></b-form-input>
        </template>
        <template v-slot:cell(iva)="row">
          <b-form-input
            v-model="row.item.iva"
            v-validate="'min_value:0.01|decimal:2'"
            type="number"
            disabled
            name="iva"
            min="0"
            trim
          ></b-form-input>
        </template>
        <template v-slot:cell(total)="row">
          <b-form-input
            v-model="row.item.total"
            v-validate="'min_value:0.01|decimal:2'"
            type="number"
            disabled
            name="total"
            min="0"
            trim
          ></b-form-input>
        </template>
      </z-table>
      <z-table-pagination
        :total-rows="totalRows"
        :per-page.sync="perPage"
        :current-page.sync="currentPage"
      />
    </div>
    <div v-else class="warning-wrapper">
      <b-alert show variant="warning">
        Parece que no has cargado ningún archivo 😕
      </b-alert>
      <z-button @click="loadFile">
        Subir Archivo
      </z-button>
    </div>
  </b-card>
</template>

<script>
import FormHeader from "@/app/components/FormHeaderVariant.vue";
import notifyMixin from "@/mixins/notify";
import Invoices from "@/services/invoices";
import pagination from "@/mixins/pagination";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";

export default {
  name: "BillingInfo",

  components: {
    FormHeader,
    ZTable,
    ZTablePagination
  },

  mixins: [notifyMixin("notifications"), pagination],

  props: {
    fromReceiver: {
      type: Boolean,
      default: false
    },
    billingInfo: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      filterByName: "",
      fields: [
        { key: "billingName", label: "Razón Social" },
        {
          key: this.RFCKey,
          label: "RFC"
        },
        { key: "email", label: "Correo" },
        { key: "subtotal", label: "Subtotal", tdClass: "price-input" },
        { key: "iva", label: "Impuesto", tdClass: "price-input" },
        { key: "total", label: "Total", tdClass: "price-input" }
      ],
      isLoading: false
    };
  },

  computed: {
    RFCKey() {
      return this.fromReceiver ? "rfc_receptor" : "rfc_emisor";
    },
    isFileLoaded() {
      return this.billingInfo.length > 0;
    },
    isNextDisabled() {
      return this.billingInfo.length === 0;
    },
    headerTitle() {
      const type = this.fromReceiver ? "Receptor" : "Emisor";
      return `Facturación vía Excel / ${type}`;
    },
    parsedBillingInfo: {
      get() {
        return this.billingInfo.filter(
          ({ billingName }) =>
            (billingName ? billingName : "")
              .toLowerCase()
              .indexOf(this.filterByName.toLowerCase()) !== -1
        );
      },
      set(newInfo) {
        this.$emit("set-billing-info", newInfo);
      }
    }
  },

  mounted() {
    if (this.billingInfo.length === 0) {
      this.loadFile();
    }
  },

  methods: {
    handleFileUpload(e) {
      const files = e.target.files;
      if (!files.length) return;
      const file = files[0];
      this.isLoading = true;
      Invoices.parseBillingInfo(file)
        .then(billingInfo => {
          billingInfo.forEach(item => {
            if (!item[this.RFCKey]) {
              throw {
                message: `Se esperaba archivo con rfc del ${
                  this.fromReceiver ? "receptor" : "emisor"
                }`
              };
            }
          });
          this.notify({
            title: "Archivo cargado exitosamente!",
            type: "success"
          });
          this.parsedBillingInfo = billingInfo.map(item => ({
            ...item,
            subtotalChanged: this.changedSubtotal
          }));
        })
        .catch(err => {
          this.notify({
            title: "Error al subir archivo csv",
            text: err.message,
            type: "error"
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.$refs.csvFileInput.value = "";
        });
    },

    changedSubtotal(item) {
      if (item.subtotal === "") return;
      item.iva = (parseFloat(item.subtotal) * 0.16).toFixed(2);
      item.total = (parseFloat(item.iva) + parseFloat(item.subtotal)).toFixed(
        2
      );
    },

    loadFile() {
      this.$refs.csvFileInput.click();
    }
  }
};
</script>

<style lang="scss">
$black: #151515;
$gray: #e0e0e0;

.price-input {
  width: 150px;
}

.warning-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .alert {
    text-align: center;
    min-width: 40%;
    font-size: 14px;
  }
}

.search-filter {
  cursor: text;
  background-color: white;
  border: 1px solid $gray;
  border-radius: 0.25rem;

  .icon {
    color: #999;
  }

  input {
    cursor: text;
    color: $black;
    min-height: 32px;
    font-size: 12px;
    border-color: transparent;
    user-select: none;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
</style>
