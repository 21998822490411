<template>
  <b-card class="issuer-form rounded-lg">
    <div class="p-3">
      <div class="h5 mb-4">
        {{ title }}
      </div>
      <b-form-group
        v-if="showGroupName"
        id="fieldset-groupName"
        class="mb-4"
        label="Nombre"
        label-for="input-groupName"
      >
        <b-form-input
          id="input-groupName"
          v-model="issuerForm.groupName"
          name="groupName"
          placeholder="Asigna un nombre al grupo de facturas"
          trim
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="fieldset-account"
        class="mb-4"
        label="Cuenta"
        label-for="select-account"
      >
        <!-- TODO: Replace v-select with z-dropdown when section needs update -->
        <!-- <v-select
          id="select-account"
          v-model="issuerForm.account"
          :options="billingAccounts"
          label-title="Selecciona la cuenta"
          text-prop="name"
          value-prop="id"
        /> -->
        <span v-show="satWSRegistered === false" class="error-validation"
          >El RFC de la cuenta no está registrado en el SAT</span
        >
      </b-form-group>
      <b-form-group
        id="fieldset-service-unit-id"
        class="mb-4"
        label="Clave de unidad de servicio"
        label-for="select-service-unit-id"
      >
        <!-- TODO: Replace v-select with z-dropdown when section needs update -->
        <!-- <v-select
          id="select-service-unit-id"
          v-model="issuerForm.unitCode"
          label-title="Selecciona la unidad de servicio"
          :options="invoiceUnitOptions"
        /> -->
      </b-form-group>
      <b-form-group
        id="fieldset-service-id"
        class="mb-4"
        label="Clave de producto o servicio"
        label-for="select-service-id"
      >
        <!-- TODO: Replace v-select with z-dropdown when section needs update -->
        <!-- <v-select
          id="select-service-id"
          v-model="issuerForm.productCode"
          label-title="Selecciona la clave de producto o servicio"
          :options="productOptions"
        /> -->
      </b-form-group>
      <b-form-group
        id="fieldset-cfdi-use"
        class="mb-4"
        label="Uso del CFDI"
        label-for="select-cfdi-use"
      >
        <!-- TODO: Replace v-select with z-dropdown when section needs update -->
        <!-- <v-select
          id="select-cfdi-use"
          v-model="issuerForm.cfdiUse"
          label-title="Selecciona la clave de producto o servicio"
          :options="cfdiUseOptions"
        /> -->
      </b-form-group>
      <b-form-group
        id="fieldset-concept"
        class="mb-4"
        label="Concepto"
        label-for="input-concept"
        :state="veeErrors.first('concept') ? false : true"
        :invalid-feedback="veeErrors.first('concept')"
      >
        <b-form-input
          id="input-concept"
          v-model="issuerForm.concept"
          v-validate="'required'"
          name="concept"
          data-vv-as="Concepto"
          trim
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="fieldset-payment-type"
        class="mb-4"
        label="Forma de pago"
        label-for="select-payment-type"
      >
        <z-dropdown
          v-model="issuerForm.paymentForm"
          :options="paymentFormOptions"
          variant="white"
          :show-header="false"
          placeholder="Selecciona la forma de pago"
          search
        />
        <!-- <v-select
          id="select-payment-type"
          v-model="issuerForm.paymentForm"
          label-title="Selecciona la forma de pago"
          :options="paymentFormOptions"
        /> -->
      </b-form-group>
      <b-form-group
        id="fieldset-payment-method"
        class="mb-4"
        label="Método de pago"
        label-for="select-payment-method"
      >
        <!-- TODO: Replace v-select with z-dropdown when section needs update -->
        <!-- <v-select
          id="select-payment-type"
          v-model="issuerForm.paymentMethod"
          label-title="Selecciona el método de pago"
          :options="paymentMethodOptions"
        /> -->
      </b-form-group>
      <b-form-group
        v-if="showFolioInput"
        id="fieldset-folio-number"
        class="mb-4"
        label="Número de folio"
        label-for="input-folio-number"
        :state="veeErrors.first('folio') ? false : true"
        :invalid-feedback="veeErrors.first('folio')"
      >
        <b-form-input
          id="input-folio-number"
          v-model="issuerForm.folio"
          v-validate="'max:15'"
          placeholder="p. ej. MX-20-W38"
          name="folio"
          data-vv-as="Folio"
          trim
        ></b-form-input>
      </b-form-group>
    </div>
    <template v-slot:footer>
      <slot name="footer"> </slot>
    </template>
  </b-card>
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import InvoicePaymentMethod from "@/constants/invoices/payment-method";
import InvoicePaymentForm from "@/constants/invoices/payment-form";
import InvoiceProduct from "@/constants/invoices/product";
import InvoiceUnit from "@/constants/invoices/unit";
import InvoiceUse from "@/constants/invoices/use";
import validateStateMixin from "@/mixins/validateState";

export default {
  name: "IssuerForm",

  components: {
    ZDropdown
  },

  mixins: [validateStateMixin],

  props: {
    data: {
      type: Object,
      default: null
    },
    showGroupName: {
      type: Boolean,
      default: false
    },
    showFolioInput: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Emisor"
    }
  },

  data() {
    return {
      invoiceUnitOptions: InvoiceUnit.options,
      productOptions: InvoiceProduct.options,
      cfdiUseOptions: InvoiceUse.options,
      paymentFormOptions: InvoicePaymentForm.options,
      paymentMethodOptions: InvoicePaymentMethod.options,
      issuerForm: {
        concept: "Servicio de mensajería",
        groupName: null,
        account: null,
        folio: null,
        unitCode: InvoiceUnit.options.find(
          el => el.value === InvoiceUnit.SERVICE_KEY
        ),
        productCode: InvoiceProduct.options.find(
          el => el.value === InvoiceProduct.SMALL_PACKAGE_DELIVERY_KEY
        ),
        cfdiUse: InvoiceUse.options.find(
          el => el.value === InvoiceUse.GENERAL_EXPENSE_KEY
        ),
        paymentForm: InvoicePaymentForm.options.find(
          el => el.value === InvoicePaymentForm.TO_DEFINE_KEY
        ),
        paymentMethod: InvoicePaymentMethod.options.find(
          el => el.value === InvoicePaymentMethod.ONE_TIME_PAYMENT_KEY
        )
      },
      decimalFormat: new Intl.NumberFormat("es-MX", {
        style: "decimal",
        maximumFractionDigits: 2
      }),
      satWSRegistered: null
    };
  },

  computed: {
    isValidIssuerForm() {
      return (
        this.isFormValid && this.isValidIssuerAccount && this.satWSRegistered
      );
    },
    isValidIssuerAccount() {
      return this.issuerForm.account != null;
    },
    billingAccounts() {
      return this.$store.state.invoices.billingAccounts.filter(
        ac => ac.billingData
      );
    }
  },

  watch: {
    isValidIssuerForm(newVal) {
      this.$emit("valid", newVal);
    },

    issuerForm: {
      deep: true,
      handler(newVal) {
        this.$emit("form-change", newVal);
      }
    },

    "issuerForm.account"(newVal) {
      const rfc = newVal?.billingData?.rfc;
      if (rfc) {
        this.setSatWSRegistrationStatus(rfc);
      }
    }
  },

  mounted() {
    if (this.data) {
      this.issuerForm.account = this.data.account;
      this.issuerForm.concept = this.data.concept;
      this.issuerForm.groupName = this.data.groupName;
      this.issuerForm.account = this.data.account;
      this.issuerForm.unitCode = this.data.unitCode;
      this.issuerForm.productCode = this.data.productCode;
      this.issuerForm.cfdiUse = this.data.cfdiUse;
      this.issuerForm.paymentForm = this.data.paymentForm;
      this.issuerForm.paymentMethod = this.data.paymentMethod;
      this.issuerForm.folio = this.data.folio;
    }
  },

  methods: {
    clearIssuerFormFields() {
      this.issuerForm.concept = "";
      this.issuerForm.groupName = "";
      this.issuerForm.account = null;
      this.issuerForm.unitCode = null;
      this.issuerForm.productCode = null;
      this.issuerForm.cfdiUse = null;
      this.issuerForm.paymentForm = null;
      this.issuerForm.paymentMethod = null;
      this.issuerForm.folio = null;
    },

    clearForm() {
      this.clearIssuerFormFields();
    },

    setSatWSRegistrationStatus(rfc) {
      this.satWSRegistered = null;
      this.$store
        .dispatch("invoices/satWSRegistrationStatus", rfc)
        .then(status => {
          this.satWSRegistered = status.exists;
        })
        .catch(err => {
          this.satWSRegistered = false;
        });
    }
  }
};
</script>
