<template>
  <div class="px-3 pt-3">
    <b-row>
      <b-col>
        <b-form-group
          id="fieldset-subtotal"
          class="mb-4"
          label="Subtotal"
          label-for="input-subtotal"
          :state="veeErrors.first('subtotal') ? false : true"
          :invalid-feedback="veeErrors.first('subtotal')"
        >
          <b-form-input
            id="input-subtotal"
            v-validate="'min_value:0.01|decimal:2'"
            :value="subtotal"
            data-vv-as="Subtotal"
            name="subtotal"
            type="number"
            :disabled="disableAmounts"
            min="0"
            trim
            @input="$emit('update:subtotal', parseFloat($event))"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="fieldset-iva"
          class="mb-4"
          label="Impuestos"
          label-for="input-iva"
          :state="veeErrors.first('iva') ? false : true"
          :invalid-feedback="veeErrors.first('iva')"
        >
          <b-form-input
            id="input-iva"
            v-validate="'min_value:0.01|decimal:2'"
            :value="iva"
            data-vv-as="IVA"
            name="iva"
            type="number"
            disabled
            min="0"
            trim
            @input="$emit('update:iva', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="fieldset-total"
          class="mb-4"
          label="Total"
          label-for="input-total"
          :state="veeErrors.first('total') ? false : true"
          :invalid-feedback="veeErrors.first('total')"
        >
          <b-form-input
            id="input-total"
            v-validate="'min_value:0.01|decimal:2'"
            :value="total"
            data-vv-as="Total"
            name="total"
            type="number"
            disabled
            min="0"
            trim
            @input="$emit('update:total', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { numberFormatter } from "@/utils/strings";

export default {
  name: "InvoiceAmount",

  props: {
    fixed: {
      type: Boolean,
      default: false
    },
    iva: {
      type: Number,
      default: 0
    },
    subtotal: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },

  computed: {
    disableAmounts() {
      return this.fixed === true;
    }
  },

  watch: {
    subtotal(newVal) {
      const subtotal = newVal || 0;
      const iva = parseFloat(numberFormatter(subtotal * 0.16));
      const total = parseFloat(numberFormatter(subtotal + iva));
      this.$emit("update:iva", iva);
      this.$emit("update:total", total);
    }
  }
};
</script>
