<template>
  <div>
    <div class="header mb-3">
      <div class="header-info">
        <p class="title">{{ title }}</p>
      </div>
      <div>
        <z-button
          class="mr-3"
          variant="secondary"
          @click="$emit('cancel-action')"
        >
          Cancelar
        </z-button>
        <z-button
          variant="primary"
          :disabled="isNextDisabled"
          @click="$emit('next-action')"
        >
          Siguiente
        </z-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormHeaderVariant",
  props: {
    title: {
      type: String,
      required: true
    },
    isNextDisabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
$blue: #0076ff;
$black: #151515;

.header {
  display: flex;
  flex-direction: row;
  align-items: flex;
  justify-content: space-between;
  flex: 1;
  margin: 1em 0em;

  .header-info {
    padding: 0 0.5em;

    .title {
      color: $black;
      font-size: 20px;
      font-weight: 500;
      margin: 4px 0px;
    }
  }
}
</style>
