<template>
  <b-card>
    <form-header :title="headerTitle" @back-action="$emit('back-action')" />
    <b-row>
      <b-col sm="12" md="6" class="mt-3 mt-md-0">
        <issuer-form
          :title="fromReceiver ? 'Emisor' : 'Receptor'"
          show-group-name
          @form-change="zubutForm = $event"
          @valid="isValidZubutForm = $event"
        >
          <template #footer>
            <invoice-amount
              :iva.sync="invoiceAmount.iva"
              :subtotal.sync="invoiceAmount.subtotal"
              :total.sync="invoiceAmount.total"
            />
          </template>
        </issuer-form>
      </b-col>
    </b-row>
    <div class="p-3">
      <z-button
        :disabled="isCreatingInvoice"
        class="mr-3"
        variant="secondary"
        @click="$emit('cancel-action')"
      >
        Cancelar
      </z-button>
      <z-button
        variant="primary"
        :disabled="disableInvoiceButton"
        :loading="isCreatingInvoice"
        @click="generateInvoices"
      >
        Facturar
      </z-button>
    </div>
  </b-card>
</template>

<script>
import FormHeader from "@/app/components/FormHeader.vue";
import InvoiceAmount from "@/app/views/Invoice/AdminBillings/InvoiceAmount";
import IssuerForm from "@/app/views/Invoice/AdminBillings/IssuerForm";
import Invoices from "@/services/invoices";
import notifyMixin from "@/mixins/notify";

export default {
  name: "ZubutSelection",

  components: {
    FormHeader,
    InvoiceAmount,
    IssuerForm
  },

  mixins: [notifyMixin("notifications")],

  props: {
    driversBillingInfo: {
      type: Array,
      required: true
    },
    fromReceiver: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      zubutForm: null,
      isValidZubutForm: false,
      isCreatingInvoice: false
    };
  },

  computed: {
    disableInvoiceButton() {
      return !this.isValidZubutForm || this.isCreatingInvoice;
    },
    headerTitle() {
      const type = this.fromReceiver ? "Receptor" : "Emisor";
      return `Generar factura vía Excel / ${type}`;
    }
  },

  beforeMount() {
    this.$store.dispatch("invoices/fetchAccounts");
    this.invoiceAmount = this.driversBillingInfo.reduce(
      (amounts, current) => ({
        subtotal: amounts.subtotal + parseFloat(current.subtotal),
        iva: amounts.iva + parseFloat(current.iva),
        total: amounts.total + parseFloat(current.total)
      }),
      { subtotal: 0, iva: 0, total: 0 }
    );
  },

  methods: {
    generateInvoices() {
      this.isCreatingInvoice = true;
      Invoices.generateMultiple(this.makeRequestData())
        .then(res => {
          if (res.failed && res.failed.length > 0) {
            const invoiceName =
              res.failed.length === 1 ? "factura" : "facturas";
            this.notify({
              title: "Errores al crear facturas",
              text: `Hubo ${res.failed.length} ${invoiceName} con error`,
              type: "warn"
            });
          } else {
            this.notify({
              title: "Facturas creadas",
              text: "Todas las facturas fueron creadas con éxito",
              type: "success"
            });
          }
          this.$router.push({ name: "invoices" }).catch(() => {});
        })
        .catch(err => {
          this.notify({
            title: "Ocurrió un error al intentar crear la factura",
            text: err.name || "",
            type: "error"
          });
        })
        .finally(() => {
          this.isCreatingInvoice = false;
        });
    },

    makeRequestData() {
      const invoiceCollection = this.driversBillingInfo.map(driverInfo => {
        const issuer = this.parseIssuer(this.zubutForm, driverInfo);
        const receiver = this.parseReceiver(this.zubutForm, driverInfo);
        return {
          issuer,
          receiver,
          amount: parseFloat(driverInfo.subtotal),
          zipCode: driverInfo.zipcode
        };
      });
      return {
        invoiceCollection,
        groupName: this.zubutForm.groupName,
        unitCode: this.zubutForm.unitCode.value,
        cfdiUse: this.zubutForm.cfdiUse.value,
        paymentForm: this.zubutForm.paymentForm.value,
        paymentMethod: this.zubutForm.paymentMethod.value,
        productCode: this.zubutForm.productCode.value,
        concept: this.zubutForm.concept
      };
    },

    parseIssuer(zubutForm, driverInfo) {
      return this.fromReceiver
        ? this.parseZubutData(zubutForm)
        : this.parseDriverData(driverInfo);
    },

    parseReceiver(zubutForm, driverInfo) {
      return this.fromReceiver
        ? this.parseDriverData(driverInfo)
        : this.parseZubutData(zubutForm);
    },

    parseZubutData(zubutForm) {
      const { billingName, rfc, fiscalRegime } = zubutForm.account.billingData;
      return {
        name: billingName,
        rfc,
        fiscalRegime
      };
    },

    parseDriverData(driverInfo) {
      return {
        name: driverInfo.billingName,
        rfc: this.fromReceiver
          ? driverInfo.rfc_receptor
          : driverInfo.rfc_emisor,
        fiscalRegime: driverInfo.fiscalRegime
      };
    }
  }
};
</script>

<style lang="scss"></style>
